import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d2016eb6 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _67e6c272 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _0f962f32 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _65ff7495 = () => interopDefault(import('../pages/cp/index.vue' /* webpackChunkName: "pages/cp/index" */))
const _140a2997 = () => interopDefault(import('../pages/donate/index.vue' /* webpackChunkName: "pages/donate/index" */))
const _62b60ac5 = () => interopDefault(import('../pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _5b6b941a = () => interopDefault(import('../pages/name/index.vue' /* webpackChunkName: "pages/name/index" */))
const _24dd0f33 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _7b011457 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _5f4fa7a6 = () => interopDefault(import('../pages/rooms/index.vue' /* webpackChunkName: "pages/rooms/index" */))
const _789fd5c7 = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _5d5c234e = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _a9a2ef08 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _34933706 = () => interopDefault(import('../pages/auth/error.vue' /* webpackChunkName: "pages/auth/error" */))
const _d59ba3fe = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _7362184a = () => interopDefault(import('../pages/cp/articles.vue' /* webpackChunkName: "pages/cp/articles" */))
const _63ddd336 = () => interopDefault(import('../pages/cp/chat.vue' /* webpackChunkName: "pages/cp/chat" */))
const _600a20bc = () => interopDefault(import('../pages/cp/donate.vue' /* webpackChunkName: "pages/cp/donate" */))
const _22f94d92 = () => interopDefault(import('../pages/cp/feedback.vue' /* webpackChunkName: "pages/cp/feedback" */))
const _57e9432c = () => interopDefault(import('../pages/cp/kino.vue' /* webpackChunkName: "pages/cp/kino" */))
const _1016c419 = () => interopDefault(import('../pages/cp/unsubs.vue' /* webpackChunkName: "pages/cp/unsubs" */))
const _75f0cda3 = () => interopDefault(import('../pages/cp/vmeste.vue' /* webpackChunkName: "pages/cp/vmeste" */))
const _10db6648 = () => interopDefault(import('../pages/profile/favorites.vue' /* webpackChunkName: "pages/profile/favorites" */))
const _fbf51a50 = () => interopDefault(import('../pages/profile/ref.vue' /* webpackChunkName: "pages/profile/ref" */))
const _02bcef86 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _76098910 = () => interopDefault(import('../pages/articles/_id/index.vue' /* webpackChunkName: "pages/articles/_id/index" */))
const _1a5afe32 = () => interopDefault(import('../pages/catalog/_id/index.vue' /* webpackChunkName: "pages/catalog/_id/index" */))
const _f93c0b26 = () => interopDefault(import('../pages/favorites/_id.vue' /* webpackChunkName: "pages/favorites/_id" */))
const _0c611f5e = () => interopDefault(import('../pages/name/_id/index.vue' /* webpackChunkName: "pages/name/_id/index" */))
const _5dbf00de = () => interopDefault(import('../pages/rooms/_id/index.vue' /* webpackChunkName: "pages/rooms/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/articles",
    component: _d2016eb6,
    name: "articles"
  }, {
    path: "/catalog",
    component: _67e6c272,
    name: "catalog"
  }, {
    path: "/copyright",
    component: _0f962f32,
    name: "copyright"
  }, {
    path: "/cp",
    component: _65ff7495,
    name: "cp"
  }, {
    path: "/donate",
    component: _140a2997,
    name: "donate"
  }, {
    path: "/favorites",
    component: _62b60ac5,
    name: "favorites"
  }, {
    path: "/name",
    component: _5b6b941a,
    name: "name"
  }, {
    path: "/privacy",
    component: _24dd0f33,
    name: "privacy"
  }, {
    path: "/profile",
    component: _7b011457,
    name: "profile"
  }, {
    path: "/rooms",
    component: _5f4fa7a6,
    name: "rooms"
  }, {
    path: "/signin",
    component: _789fd5c7,
    name: "signin"
  }, {
    path: "/termsofuse",
    component: _5d5c234e,
    name: "termsofuse"
  }, {
    path: "/unsubscribe",
    component: _a9a2ef08,
    name: "unsubscribe"
  }, {
    path: "/auth/error",
    component: _34933706,
    name: "auth-error"
  }, {
    path: "/auth/success",
    component: _d59ba3fe,
    name: "auth-success"
  }, {
    path: "/cp/articles",
    component: _7362184a,
    name: "cp-articles"
  }, {
    path: "/cp/chat",
    component: _63ddd336,
    name: "cp-chat"
  }, {
    path: "/cp/donate",
    component: _600a20bc,
    name: "cp-donate"
  }, {
    path: "/cp/feedback",
    component: _22f94d92,
    name: "cp-feedback"
  }, {
    path: "/cp/kino",
    component: _57e9432c,
    name: "cp-kino"
  }, {
    path: "/cp/unsubs",
    component: _1016c419,
    name: "cp-unsubs"
  }, {
    path: "/cp/vmeste",
    component: _75f0cda3,
    name: "cp-vmeste"
  }, {
    path: "/profile/favorites",
    component: _10db6648,
    name: "profile-favorites"
  }, {
    path: "/profile/ref",
    component: _fbf51a50,
    name: "profile-ref"
  }, {
    path: "/",
    component: _02bcef86,
    name: "index"
  }, {
    path: "/articles/:id",
    component: _76098910,
    name: "articles-id"
  }, {
    path: "/catalog/:id",
    component: _1a5afe32,
    name: "catalog-id"
  }, {
    path: "/favorites/:id",
    component: _f93c0b26,
    name: "favorites-id"
  }, {
    path: "/name/:id",
    component: _0c611f5e,
    name: "name-id"
  }, {
    path: "/rooms/:id",
    component: _5dbf00de,
    name: "rooms-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
