<template>
  <div :class="`${ !isDesktop ? 'mobile' : '' }`">
    <Mobile v-if="!isDesktop" />

    <nuxt />
    
    <Error v-if="error" :error="error" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Mobile: () => import('~/components/Mobile.vue'),
    Error: () => import('~/components/Error.vue')
  },
  watch: {
    fullPath(val) {
      document.body.style.overflow = ''
    },
  },
  computed: {
    ...mapState(['error', 'isDesktop']),
    fullPath() {
      return this.$route.fullPath
    }
  },
  mounted() {
    // const scripts = document.querySelectorAll(`script`)
    // scripts.forEach(el => {
    //   if (el.src.includes(`vak345.com`)) {
    //     document.body.removeChild(el)
    //   }
    // })

    // const blocks = document.querySelectorAll('div')
    // blocks.forEach(el => {
    //   if (el.style.zIndex === '2147483647') {
    //     console.log(el)
    //     document.body.removeChild(el)
    //   }
    // })

    document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    window.addEventListener('resize', () => { 
      document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    })
  }
}
</script>